<template>
  <div class="youtube-detail">
    <div v-if="!isLoading">
      <div class="detail">
        <div class="title">
          <a :href="'https://www.youtube.com/watch?v=' + item.hash" target="_blank">{{ item.title }}</a>
        </div>
        <YouTubeChatGraph :item="item" :groupedData="groupedData" />
      </div>
    </div>
    <div v-else>
      <div class="loading">
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import YouTubeChatGraph from './YouTubeChatGraph.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      chats: null,
      zimaku: null,
      groupedData: null,
    };
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        const chatsResponse = await axios.get(`https://r1z9vun2s9.execute-api.ap-northeast-1.amazonaws.com/Prod/youtube-data/${this.item.hash}/chat`, {
          headers: { 'X-Api-Key': 'bh4tA4FcXD2IUjqbqgXNC1hnb1V2jojM6b2cD96u' }
        });
        this.chats = chatsResponse.data.body ? JSON.parse(chatsResponse.data.body) : [];
      } catch (error) {
        console.error('Error fetching chats data:', error);
        this.chats = [];
      }

      try {
        const zimakuResponse = await axios.get(`https://r1z9vun2s9.execute-api.ap-northeast-1.amazonaws.com/Prod/youtube-data/${this.item.hash}/zimaku`, {
          headers: { 'X-Api-Key': 'bh4tA4FcXD2IUjqbqgXNC1hnb1V2jojM6b2cD96u' }
        });
        if (zimakuResponse.data.statusCode === 400) {
          this.zimaku = [];
        } else {
          this.zimaku = zimakuResponse.data.body ? JSON.parse(zimakuResponse.data.body) : [];
        }
      } catch (error) {
        console.error('Error fetching zimaku data:', error);
        this.zimaku = [];
      }

      this.groupedData = this.groupDataByHour(this.chats, this.zimaku);

      this.isLoading = false;
    },
    groupDataByHour(chats, subtitles) {
      const groups = [];
      if(subtitles && subtitles.length > 0){
        const maxTimestamp = Math.max(this.getChatMaxTimestamp(chats), this.getSubtitleMaxTimestamp(subtitles));
        let currentTimestamp = 0;

        while (currentTimestamp <= maxTimestamp) {
          const nextTimestamp = currentTimestamp + 60;

          const group = {
            startTime: this.formatTimestamp(currentTimestamp),
            endTime: this.formatTimestamp(nextTimestamp),
            chats: chats.filter(chat => this.getTimestampInSeconds(chat.timestampText) >= currentTimestamp && this.getTimestampInSeconds(chat.timestampText) < nextTimestamp),
            subtitles: subtitles.filter(subtitle => subtitle.start >= currentTimestamp && subtitle.start < nextTimestamp)
          };

          groups.push(group);
          currentTimestamp = nextTimestamp;
        }
      }else{
        const maxTimestamp = this.getChatMaxTimestamp(chats);
        let currentTimestamp = 0;

        while (currentTimestamp <= maxTimestamp) {
          const nextTimestamp = currentTimestamp + 60;

          const group = {
            startTime: this.formatTimestamp(currentTimestamp),
            endTime: this.formatTimestamp(nextTimestamp),
            chats: chats.filter(chat => this.getTimestampInSeconds(chat.timestampText) >= currentTimestamp && this.getTimestampInSeconds(chat.timestampText) < nextTimestamp),
            subtitles: [{
              "text":"字幕なし"
            }]
          };

          groups.push(group);
          currentTimestamp = nextTimestamp;
        }
      }

      return groups;
    },
    getChatMaxTimestamp(chats) {
      return chats.reduce((max, chat) => {
        const timestamp = this.getTimestampInSeconds(chat.timestampText);
        return Math.max(max, timestamp);
      }, 0);
    },
    getSubtitleMaxTimestamp(subtitles) {
      if (!subtitles || subtitles.length === 0) {
        return 0;
      }

      return subtitles.reduce((max, subtitle) => {
        return Math.max(max, subtitle.start);
      }, 0);
    },
    getTimestampInSeconds(timestamp) {
      const parts = timestamp.split(':').map(parseFloat);
      let seconds = 0;
      for (let i = 0; i < parts.length; i++) {
        seconds = seconds * 60 + parts[i];
      }
      return seconds;
    },
    formatTimestamp(timestamp) {
      let hours = Math.floor(timestamp / 3600);
      let minutes = Math.floor((timestamp % 3600) / 60);
      let seconds = timestamp % 60;

      // ゼロパディング
      hours = hours.toString().padStart(2, '0');
      minutes = minutes.toString().padStart(2, '0');
      seconds = seconds.toString().padStart(2, '0');

      return `${hours}:${minutes}:${seconds}`;
    }
  },
  components: {
    YouTubeChatGraph
  }
};
</script>

<style scoped>
.youtube-detail {
  width: 1000px;
  height: 1000px;
}

.detail{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  margin-top: 25px;
}

a {
  color: inherit;
}
</style>

<template>
  <div class="chat-graph">
    <div v-if="!isLoading">
      <LineChart :chartData="datacollection" @data-clicked="handleDataClicked"/>
    </div>
    
    <div v-if="showData">
      <div class="meta">
        {{ showData.startTime }}～{{ showData.endTime }}
      </div>
      <div class="link">
        <a :href="'https://www.youtube.com/live/' + item.hash + '?t=' + getTimestampInSeconds(showData.startTime)" target="_blank">ここから再生</a>
      </div>
      <div class="zimaku-content">
        <div>
          [字幕]
        </div>
        <div class="zimaku" v-for="(subtitle, index) in showData.subtitles" :key="index">
            {{ subtitle.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from './LineChart.vue'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    groupedData: {
      type: Object
    }
  },
  data() {
    return {
      isLoading: true,
      datacollection: null,
      // chartOptions: {
      //   responsive: true,
      //   maintainAspectRatio: false,
      //   scales: {
      //     y: {
      //       display: false, // 縦軸の数字を非表示にする
      //     }
      //   },
      //   plugins: {
      //     legend: {
      //       display: false,
      //     },
      //     tooltip: {
      //       enabled: true,
      //       callbacks:{
      //         title: function(context) {
      //           const originalLabel = context[0].label
      //           return originalLabel
      //         },
      //         label: function() {
      //           return null
      //         },
      //         fotter: function() {
      //           return null
      //         },
      //       }
      //     }
      //   },
      //   onClick: (e) => {
      //     const chart = this.$refs.chart;
      //     console.log(chart)
      //     console.log(
      //       chart.getElementsAtEventForMode(
      //         e,
      //         'nearest',
      //         { intersect: true },
      //         false
      //       )
      //     );
      //   },
      // },
      showData: null
    }
  },
  mounted () {
    this.fillData()
  },
  methods: {
    fillData () {
      const labels = [];
      const data = [];
      if(! this.groupedData){
        return
      }
      this.groupedData.forEach(element => {
        labels.push(element.startTime);
        data.push(element.chats.length);
      });

      this.datacollection = {
        labels: labels,
        datasets: [
          {
            lineTension: 0.5,
            label: 'Chat',
            backgroundColor: '#f87979',
            borderColor: "orange",
            data: data
          }
        ]
      }

      this.isLoading = false
    },
    handleDataClicked(index) {
      this.showData = this.groupedData[index];
    },
    getTimestampInSeconds(timestamp) {
      const parts = timestamp.split(':').map(parseFloat);
      let seconds = 0;
      for (let i = 0; i < parts.length; i++) {
        seconds = seconds * 60 + parts[i];
      }
      return seconds;
    },
  },
  components: {
    LineChart
  }
};
</script>

<style scoped>
.chat-graph {
  width: 90%;
  height: 500px
}

a {
  color: inherit;
}

.meta{
  margin-top: 50px;
}

.zimaku-content{
  height: 400px;
  margin: 10px;
  overflow:auto
}

.zimaku{
  font-size: 14px;
}
</style>

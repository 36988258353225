<template>
  <div class="youtube-item">
    <div class="title">
      <a :href="'https://www.youtube.com/watch?v=' + item.hash" target="_blank">{{ item.title }}</a>
    </div>
    <div class="y-content">
      <div class="y-left">
        <div class="y-thnmb">
          <a :href="'https://www.youtube.com/watch?v=' + item.hash" target="_blank"><img class="thumbnail" :src="item.thumbnails" alt="動画サムネイル"></a>
        </div>
        <div class="description">
          {{ item.description }}
        </div>
        <div class="show-detail">
          <button class="popup-button" @click="toggleDetailPopup">詳細を表示</button>
        </div>
      </div>
      <div class="y-right">
        <img class="wordcloud" :src="'https://dw7a5nw87fqac.cloudfront.net/detail/' + item.hash + '/wordcloud.png'" alt="ワードクラウド" @click="togglePopup">
        <div class="wordcloud-title">
          [頻出チャット]
        </div>
      </div>
    </div>
    <div class="date">{{ new Date(item.date).toLocaleDateString('ja-JP', { timeZone: 'Asia/Tokyo' }) }}</div>

    <!-- ポップアップ -->
    <div v-if="showPopup" class="popup" @click="togglePopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="togglePopup">Close</button>
        <img class="popup-image" :src="'https://dw7a5nw87fqac.cloudfront.net/detail/' + item.hash + '/wordcloud.png'" alt="ワードクラウド">
      </div>
    </div>

    <!-- 詳細ポップアップ -->
    <div v-if="showDetailPopup" class="popup" @click="toggleDetailPopup">
      <div class="popup-content" @click.stop>
        <button class="close-button" @click="toggleDetailPopup">Close</button>
        <YouTubeDetail :item="item" @close="toggleDetailPopup" />
      </div>
    </div>
  </div>
</template>

<script>
import YouTubeDetail from './YouTubeDetail.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showPopup: false,
      showDetailPopup: false
    };
  },
  methods: {
    togglePopup() {
      this.showPopup = !this.showPopup;
    },
    toggleDetailPopup() {
      this.showDetailPopup = !this.showDetailPopup;
    }
  },
  components: {
    YouTubeDetail
  }
};
</script>

<style scoped>
/* デフォルトのスタイル */
.youtube-item {
  width: 720px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-weight: bold;
  margin: 20px;
  padding: 0.5em 1em;
  background: rgba(0, 0, 0, 0.6);
  border: solid 3px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  /* スマートフォン向けのスタイル */
  .youtube-item {
    width: 100%;
  }
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

a {
  color: inherit;
}

.y-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.y-left {
  width: 300px;
}

.y-thnmb {
  margin-top: 25px;
  overflow: hidden;
}

.thumbnail {
  width: 295px;
  margin: -30px 0 -30px 5px;
}

.description {
  font-size: 14px;
  margin-top: 20px;
  height: 100px;
  width: 100%;
  overflow: hidden;
}

.y-right {
  width: 420px;
  overflow: hidden;
}

.wordcloud {
  width: 550px;
  margin: -75px;
  cursor: pointer;
}

.wordcloud-title {
  font-size: 14px;
  margin-top: -40px;
}

.date {
}

@media screen and (max-width: 768px) {
  /* スマートフォン向けのスタイル */
  .y-right {
    width: 100%;
  }
  .wordcloud {
    width: 100%;
  }

  .show-detail{
    display: none;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background-color: #090a0f;
  padding: 20px;
  position: relative;
}

.popup-image {
  max-width: 150%;
  max-height: 150%;
}

@media screen and (max-width: 768px) {
  /* スマートフォン向けのスタイル */
  .popup-image {
    max-width: 100%;
    max-height: 100%;
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #fff;
  padding: 5px;
}

</style>

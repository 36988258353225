<template>
  <div class="home">
    <div>
      <input class="searc-title" type="text" v-model="searchQuery" placeholder="Search Title">
      <button class="sort-button" @click="toggleSortOrder"> {{ sortOrder === 'desc' ? '新しい順' : '古い順' }}▼</button>
    </div>
    <div v-if="!isLoading">
      <div v-if="filteredItems.length === 0">No results found</div>
      <div v-else>Found {{ filteredItems.length }} results</div>
      <div class="items">
        <YouTubeItemVue v-for="(item, index) in displayedItems" :key="index" :item="item" />
      </div>
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 0">Prev</button>
        <span class="page">Page {{ currentPage + 1 }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages - 1">Next</button>
      </div>
    </div>

    <div v-else>
      <div class="loading">
        Loading...
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import YouTubeItemVue from '../components/YouTubeItem.vue';

export default {
  components: {
    YouTubeItemVue
  },
  data() {
    return {
      isLoading: true,
      items: null,
      sortOrder: 'desc',
      searchQuery: '',
      currentPage: 0,
      itemsPerPage: 6
    };
  },
  computed: {
    filteredItems() {
      if (!this.items) return null;

      // 検索クエリを半角または全角の空白で分割
      const searchKeywords = this.searchQuery.split(/\s+/);

      return this.items.filter(item => {
        // 各キーワードについて、タイトルに含まれるかどうかをチェック
        return searchKeywords.every(keyword => {
          const regex = new RegExp(keyword, 'i');
          return regex.test(item.title);
        });
      }).sort((a, b) => {
        if (this.sortOrder === 'asc') {
          return new Date(a.date) - new Date(b.date);
        } else {
          return new Date(b.date) - new Date(a.date);
        }
      });
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },
    displayedItems() {
      const startIndex = this.currentPage * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredItems.slice(startIndex, endIndex);
    }
  },
  async mounted() {
    await this.getData();
  },
  watch: {
    searchQuery() {
      this.currentPage = 0; // 検索時にページをリセット
    }
  },
  methods: {
    async getData() {
      try {
        const response = await axios({
          method: 'GET',
          url: 'https://r1z9vun2s9.execute-api.ap-northeast-1.amazonaws.com/Prod/youtube-data',
          headers: {
            'X-Api-Key': 'bh4tA4FcXD2IUjqbqgXNC1hnb1V2jojM6b2cD96u'
          }
        });
        const responseData = JSON.parse(response.data.body);
        this.items = responseData;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      this.isLoading = false;
    },
    toggleSortOrder() {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
      this.currentPage = 0; // ソート時にページをリセット
    },
    nextPage() {
      if (this.currentPage < this.totalPages - 1) {
        this.currentPage++;
        this.scrollToTop();
      }
    },
    prevPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.scrollToTop();
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped>

.home{
  width: 100%;
}
.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pagination {
  margin-top: 10px;
}

.searc-title{
  height: 22px;
  margin-right: 30px;
}

.sort-button{
  width: 100px;
}

.page{
  width: 150px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
<template>
  <div class="line-chart-container">
    <Line ref="chart" :data="chartData" :options="chartOptions" />
    <div class="chat-title">
      [チャットの勢い(グラフクリックで字幕表示)]
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  name: 'LineChart',
  components: { Line },
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      datacollection: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            display: false, // 縦軸の数字を非表示にする
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            callbacks:{
              title: function(context) {
                const originalLabel = context[0].label
                return originalLabel
              },
              label: function() {
                return null
              },
              footer: function() {
                return null
              },
            }
          }
        },
        onClick: (e) => {
          const chart = this.$refs.chart.chart;
          const elements = chart.getElementsAtEventForMode(
            e,
            'nearest',
            { intersect: true },
            false
          );
          if (elements.length > 0) {
            const index = elements[0].index;
            this.$emit('data-clicked', index);
          }
        },
      },
      showData: null
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>


<style scoped>
.line-chart-container {
  width: 100%;
  height: 300px;
}

.chat-title{
  font-size: 14px;
}
</style>
<template>
  <div id="app">
    <div class="header">
      <div>Kani Archives</div>
    </div>

    <router-view></router-view>
    <div class="stars">
      <div class="star" v-for="(star, index) in stars" :key="index" :style="{ top: star.top + 'vh', left: star.left + 'vw', width: star.size + 'px', height: star.size + 'px', animationDuration: star.duration + 's' }"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      stars: []
    };
  },
  mounted() {
    this.generateStars();
  },
  methods: {
    generateStars() {
      for (let i = 0; i < 100; i++) {
        const star = {
          top: Math.random() * 100,
          left: Math.random() * 100,
          size: Math.random() * 3 + 1,
          duration: Math.random() * 40 + 60 // よりゆっくりと流れるように修正
        };
        this.stars.push(star);
      }
    }
  }
};
</script>

<style>
#app {
  font-family:"Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFFFFF;
}

body {
  height: 100%;
  width: 100%;
  margin: 100px 0 30px 0;
  background: #090a0f;
}

button{
  background-color: #333;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
  font-weight: bold;
  font-size: 32px;
}

.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1000;
}

.star {
  position: absolute;
  background-color: #FFFFEF;
  border-radius: 50%;
  animation: twinkle 1s infinite, flow 60s ease-out infinite; /* 星の点滅アニメーションと流れるアニメーションを同時に適用 */
  z-index: -1000;
}

/* 星の点滅アニメーション */
@keyframes twinkle {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

/* 星の流れるアニメーション */
@keyframes flow {
  0% { transform: translateY(100vh); }
  100% { transform: translateY(-100vh); }
}
</style>
